import React from "react";
import Fade from "react-reveal/Fade";
import theme from "@styles/theme";
import Button from "@common/buttons/Button";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { EffectFade } from "swiper";
SwiperCore.use([Autoplay, Navigation]);

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

function DishSoapHero() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column-reverse;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/1IVYLXytY121S1WKcGQ2Gu/4ce15a7a7ba1f8a8523417b8cf6ce993/Asset_117.png?h=1200");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @media (max-width: 1000px) {
            min-height: 540px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 420px;
          }
        `}
      ></div>
      <div
        css={`
          flex-basis: 50%;
          background-image: radial-gradient(circle at 50% center, #cdd5f6, #cdd5f6 2%, #9492cf);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            min-height: 430px;
          }
          @media (max-width: 800px) {
            min-height: 370px;
          }
          @media (max-width: 600px) {
            min-height: 350px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: white;
                font-family: ${theme.fonts.paragraph};
                text-transform: uppercase;
                font-size: 1.9rem;
                font-weight: 700;
                max-width: 340px;
                line-height: 1.2;
                letter-spacing: 2px;

                margin: 0px auto 13px;
              `}
            >
              OUR POWERFUL FORMULAS ARE NOW AVAILABLE IN
            </p>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={
                  "https://images.ctfassets.net/1s5kub6totmj/2Hw3IDDhNQwKDwAVqnZ4KH/7919f3afb8587c4a95fd76d743b96389/DING_1.png?h=250"
                }
                width={20}
                height={20}
                css={`
                  position: absolute;
                  left: -35px;
                  top: -7px;
                `}
              />
              <h1
                css={`
                  color: white;
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 5rem;
                  font-weight: 700;
                  letter-spacing: 2px;
                  margin: 0px;
                `}
              >
                ETERNAL
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <img
              src="https://images.ctfassets.net/1s5kub6totmj/5C9eYkN8TMxx8RRQU83iJq/fc372c320d267934972cac5e19daaf59/Asset_107_3x.png?h=850"
              alt="Aluminum"
              css={`
                max-width: 320px;
                margin-top: -20px;
                margin-bottom: -15px;
                z-index: 99;
                position: relative;
              `}
            />
          </Fade>
          <Fade ssrReveal delay={600} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={
                  "https://images.ctfassets.net/1s5kub6totmj/2Hw3IDDhNQwKDwAVqnZ4KH/7919f3afb8587c4a95fd76d743b96389/DING_1.png?h=250"
                }
                width={20}
                height={20}
                css={`
                  position: absolute;
                  right: -35px;
                  bottom: 5px;
                `}
              />
              <h1
                css={`
                  color: white;
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 5rem;
                  font-weight: 700;
                  letter-spacing: 2px;
                  margin: 0px;
                `}
              >
                BOTTLES
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={800} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: white;
                font-family: ${theme.fonts.paragraph};
                text-transform: uppercase;
                font-size: 1.9rem;
                font-weight: 700;
                max-width: 300px;
                line-height: 1.2;
                letter-spacing: 2px;

                margin: 2px auto 5px;
              `}
            >
              TO BE REFILLED AND REUSED FOR ETERNITY.
            </p>
          </Fade>
          <Fade ssrReveal delay={1000} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="/products/shop/all" modifiers={["primary", "medium"]}>
                SHOP NOW
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function MomDaughterFillingSoapHero() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/4ndCWMDmPLxzbCKdvkZlRm/ae31e72fe84d1c820f32ec3e60179d9c/Asset_108_2x__1_.png?h=1200");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            min-height: 370px;
          }
          @media (max-width: 800px) {
            min-height: 320px;
          }
          @media (max-width: 600px) {
            min-height: 290px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={"/images/icons/dings/blue/ding.svg"}
                width={33}
                height={33}
                css={`
                  position: absolute;
                  right: 0px;
                  top: -22px;
                `}
              />
              <h1
                css={`
                  color: ${theme.colors.primary};
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 4.5rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                `}
              >
                REFILLING NOT LANDFILLING
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: ${theme.colors.primary};
                font-family: ${theme.fonts.paragraph};
                font-size: 1.9rem;
                font-weight: 500;
                max-width: 300px;
                line-height: 1.5;
                margin: 2px auto 0px;
              `}
            >
              We're on a mission to help more people switch from a bad plastic habit to a cleaner ritual.
            </p>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="/products/shop/all" modifiers={["primary", "medium"]}>
                SHOP NOW
              </Button>
            </div>
          </Fade>
        </div>
      </div>
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/37kihszkHBrpNCWcODG5gb/abce10ca63b539534bb6fab7977db011/Asset_115_3x.png?h=1200");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @media (max-width: 1000px) {
            min-height: 540px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 350px;
          }
        `}
      ></div>
    </div>
  );
}

function MomDaughterBannerFillingSoapHero() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/5pDFNZk0tA6AtvDrI42CbZ/ef5dd6d421c92deec124025176a7a003/Asset_160_3x.png?h=1200");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @media (max-width: 1000px) {
            min-height: 540px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 350px;
          }
        `}
      ></div>
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/55Gyg5qgSzL2Q9BXAwsC56/47d4a3ad7cdc47a86380a73a431470e1/Asset_155_3x.png?h=1200");
          background-position-y: center;
          background-position-x: left;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            min-height: 370px;
          }
          @media (max-width: 800px) {
            min-height: 320px;
          }
          @media (max-width: 600px) {
            min-height: 290px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={"/images/icons/dings/blue/ding.svg"}
                width={33}
                height={33}
                css={`
                  position: absolute;
                  right: 0px;
                  top: -22px;
                `}
              />
              <h1
                css={`
                  color: ${theme.colors.primary};
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 4.5rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                `}
              >
                REFILLING NOT LANDFILLING
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: ${theme.colors.primary};
                font-family: ${theme.fonts.paragraph};
                font-size: 1.9rem;
                font-weight: 500;
                max-width: 300px;
                line-height: 1.5;
                margin: 2px auto 0px;
              `}
            >
              We're on a mission to help more people switch from a bad plastic habit to a cleaner ritual.
            </p>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="/products/shop/all" modifiers={["primary", "medium"]}>
                SHOP NOW
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function LaundrySheets() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/2HGR11TvSV1RVIqrLewp1S/169cdd7a5730199b1984d312c6bd69dd/banner_gif.gif?h=1200");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @media (max-width: 1000px) {
            min-height: 540px;
            background-image: url("https://images.ctfassets.net/1s5kub6totmj/ug9ojHLqxR7SKCpOEorSN/f5dcabf8421724e50140fbb60c6f7133/gif_mobile.gif?h=450");
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 350px;
          }
        `}
      ></div>
      <div
        css={`
          flex-basis: 50%;
          background: #bdcce8;
          background-position-y: center;
          background-position-x: left;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            min-height: 370px;
          }
          @media (max-width: 800px) {
            min-height: 320px;
          }
          @media (max-width: 600px) {
            min-height: 290px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={"/images/icons/dings/blue/ding.svg"}
                width={33}
                height={33}
                css={`
                  position: absolute;
                  right: 0px;
                  top: -22px;
                `}
              />
              <h1
                css={`
                  color: ${theme.colors.primary};
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 4.5rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                `}
              >
                SACRED SHEETS FOR SACRED SHEETS
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: ${theme.colors.primary};
                font-family: ${theme.fonts.paragraph};
                font-size: 1.9rem;
                font-weight: 500;
                max-width: 400px;
                line-height: 1.5;
                margin: 2px auto 0px;
              `}
            >
              Introducing the only laundry detergent sheets with 3 stain-fighting enzymes for a uniquely powerful clean
            </p>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button
                link
                href="/products/laundry-detergent-sheets-fresh-linen-60ct?plan=subscription"
                modifiers={["primary", "medium"]}
              >
                SHOP NOW
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function LaundrySheetSamples() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        background-color: #81b1d1;
        background-image: url("https://images.ctfassets.net/1s5kub6totmj/4MZHmPBNd9IUncVD5UBeZg/13dd3c91629f23f1347242a48a1f592d/Asset_4_2x.png?h=1200&q=70");
        background-position-y: center;
        background-position-x: center;
        background-size: cover;
        min-height: 600px;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/4R62rrRcvMD7itxAJlUMgr/d48818b4ca784fdc835bf5e2ae27512f/Moe__1_.png?h=1200");
          background-position-x: right;
          background-position-y: center;
          background-size: auto 80%; /* Adjusted size for height */
          background-repeat: no-repeat;
          @media (max-width: 1200px) {
            background-size: 80%; /* Adjusted size for height */
          }
          @media (max-width: 1000px) {
            min-height: 540px;
            background-position-x: center;
            background-size: auto 80%; /* Adjusted size for height */
            background-image: url("https://images.ctfassets.net/1s5kub6totmj/4R62rrRcvMD7itxAJlUMgr/d48818b4ca784fdc835bf5e2ae27512f/Moe__1_.png?h=450");
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 400px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 350px;
          }
        `}
      >
        {/* <img
          src="https://images.ctfassets.net/1s5kub6totmj/4R62rrRcvMD7itxAJlUMgr/d48818b4ca784fdc835bf5e2ae27512f/Moe__1_.png?h=1200"
          css={`
            width: 100%;
          `}
          alt="laundry sheet samples"
        /> */}
      </div>
      <div
        css={`
          flex-basis: 50%;

          background-position-y: center;
          background-position-x: left;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (max-width: 1000px) {
            min-height: 370px;
            align-items: center;
          }
          @media (max-width: 800px) {
            min-height: 320px;
          }
          @media (max-width: 600px) {
            min-height: 290px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            padding-left: 100px;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
              padding-left: 70px;
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
              padding-left: 20px;
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.3);
              padding-left: 0px;
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(1);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
                padding-left: 10px;
                padding-right: 10px;
              `}
            >
              <h1
                css={`
                  color: ${theme.colors.primary};
                  font-family: ${theme.fonts.paragraph};
                  text-transform: uppercase;
                  font-size: 1.4rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                  margin-bottom: 10px;
                `}
              >
                CLEAN CULT PRESENTS THE:
              </h1>
            </div>
          </Fade>

          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                padding-left: 20px;
                padding-right: 20px;
              `}
            >
              <img
                src="https://images.ctfassets.net/1s5kub6totmj/1UzqfLKPc1riCNRfqOKY5w/ebaa3094fafebc41d9fb9627bee1f5ec/Asset_5_2x.png?h=1000"
                alt="laundry sheet samples"
                css={`
                  max-width: 400px;
                  width: 100%;
                  margin-bottom: 10px;
                `}
              />
            </div>
          </Fade>
          <Fade ssrReveal delay={300} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
                padding-left: 10px;
                padding-right: 10px;
              `}
            >
              <h1
                css={`
                  color: ${theme.colors.primary};
                  font-family: ${theme.fonts.paragraph};
                  text-transform: uppercase;
                  font-size: 1.9rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                `}
              >
                YOUR THUMBS WILL NEVER BE THE SAME
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: ${theme.colors.primary};
                font-family: ${theme.fonts.paragraph};
                font-size: 1.5rem;
                font-weight: 500;
                max-width: 400px;
                line-height: 1.5;
                margin: 2px auto 0px;
                padding-left: 10px;
                padding-right: 10px;
              `}
            >
              A highly targeted workout that exercises the three muscles which control your thumb movement.
            </p>
          </Fade>
          <Fade ssrReveal delay={500} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="https://strongthumbs.com" modifiers={["yellow", "medium"]}>
                GET YOUR FREE EQUIPMENT
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function AllHailAluminumHero() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        background-image: url("https://images.ctfassets.net/1s5kub6totmj/5cmcI3MfmCZztW8J7pwqOD/816035fd74f147d514945e947dbfb305/costcobanner_EXT_1.png?h=1000");
        background-position-y: center;
        background-position-x: right;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 1600px) {
          background-position-x: center;
        }
        @media (max-width: 1000px) {
          height: fit-content;
          min-height: 500px;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/6uhNQLqkelcoH9cym5Y0IX/6017f98014cc91eeb309f1bd4a8dfb97/costcobannerMOBILE_1.png?h=1000");
          flex-direction: column-reverse;
          background-position-x: center;
          background-position-y: bottom;
        }
        @media (max-width: 1000px) {
          span {
            color: white !important;
          }
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/s7QD0U5Yn1jAyAVJZmLle/8ebecc8bc8c618137c9b7abfb204fc9a/Asset_116_3x.png?h=600");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          opacity: 0;
          @media (max-width: 1000px) {
            min-height: 540px;
            opacity: 1;
            background-image: none;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 30px;
          }
        `}
      ></div>
      <div
        css={`
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (max-width: 1000px) {
            min-height: 430px;
            align-items: center;
            background-image: none;
          }
          @media (max-width: 800px) {
            min-height: 370px;
          }
          @media (max-width: 600px) {
            min-height: 350px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            padding-left: 80px;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: 1000px) {
              padding-left: 0px;
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <span
              css={`
                color: #3e60aa;
                font-family: ${theme.fonts.paragraph};
                text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 1.5;
                font-weight: 900;
                max-width: 310px;
                letter-spacing: 1px;

                margin: 0px auto 13px;
              `}
            >
              NOW AT COSTCO
            </span>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <h1
                css={`
                  color: #202c67;
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 4.3rem;
                  font-weight: 700;
                  line-height: 1;
                  letter-spacing: 2px;
                  margin: 0px;
                `}
              >
                BIG CLEAN <br /> ENERGY
              </h1>
            </div>
          </Fade>

          <Fade ssrReveal delay={600} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: #202c67;
                font-family: ${theme.fonts.paragraph};
                text-transform: uppercase;
                font-size: 1.4rem;
                line-height: 1.3;
                font-weight: 900;
                max-width: 240px;
                letter-spacing: 2px;

                margin: 5px auto 5px;
              `}
            >
              3-PACK OF ALL-PURPOSE CLEANERS IN FRESH, NATURE-INSPIRED SCENTS.
            </p>
          </Fade>
          <Fade ssrReveal delay={800} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="/store-locator" modifiers={["danger", "medium"]}>
                FIND STORES NEAR YOU
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function RefillHero() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 65vh;
        min-height: 600px;
        background-image: url("https://images.ctfassets.net/1s5kub6totmj/6LCxiQQMhi6bd1zRD3FBZy/fb16e9254ec805ca38d68ece6779a446/Desktop-Banner_Wild_Lavender-Water.png?q=70&h=1000");
        background-position-y: center;
        background-position-x: right;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 1000px) {
          height: fit-content;
          flex-direction: column-reverse;
          background-position-x: center;
          background-position-y: center;
        }
        @media (max-width: ${theme.breakPoints.xs}) {
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/6dLSESLJ61goQnCGtlWAdc/28c2cbd241bf307c2ba81f91ee6cc77a/Mobile_Full-Banner_Wild_Lavender-Water.png?h=1000");
          background-position-y: bottom;
        }
      `}
    >
      <div
        css={`
          flex-basis: 50%;
          background-image: url("https://images.ctfassets.net/1s5kub6totmj/s7QD0U5Yn1jAyAVJZmLle/8ebecc8bc8c618137c9b7abfb204fc9a/Asset_116_3x.png?h=600");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          opacity: 0;
          @media (max-width: 1000px) {
            min-height: 540px;
            opacity: 1;
            background-image: none;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            min-height: 450px;
          }
          @media (max-width: ${theme.breakPoints.xs}) {
            min-height: 30px;
          }
        `}
      ></div>
      <div
        css={`
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          @media (max-width: 1000px) {
            min-height: 430px;
            align-items: center;
            background-image: none;
          }
          @media (max-width: 800px) {
            min-height: 370px;
          }
          @media (max-width: 600px) {
            min-height: 350px;
          }
        `}
      >
        <div
          css={`
            text-align: center;
            transform: scale(1.5);
            text-align: center;
            @media (max-width: ${theme.breakPoints.xl}) {
              transform: scale(1.3);
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              transform: scale(1.2);
            }
            @media (max-width: ${theme.breakPoints.md}) {
              transform: scale(1.1);
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              transform: scale(1);
            }
            @media (max-width: ${theme.breakPoints.xs}) {
              transform: scale(0.9);
            }
          `}
        >
          <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                position: relative;
                width: fit-content;
                margin: auto;
              `}
            >
              <img
                alt={"Ding"}
                src={"/images/icons/dings/white/ding.svg"}
                width={33}
                height={33}
                css={`
                  position: absolute;
                  right: 0px;
                  top: -22px;
                `}
              />
              <h1
                css={`
                  color: ${theme.colors.white};
                  font-family: ${theme.fonts.headline};
                  text-transform: uppercase;
                  font-size: 4.5rem;
                  font-weight: 700;
                  letter-spacing: 0.5;
                  line-height: 1;
                  margin: 0px;
                  max-width: 400px;
                `}
              >
                CLEAN ALWAYS MEANS CLEAN
              </h1>
            </div>
          </Fade>
          <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
            <p
              css={`
                color: ${theme.colors.white};
                font-family: ${theme.fonts.paragraph};
                font-size: 1.9rem;
                font-weight: 500;
                max-width: 300px;
                line-height: 1.5;
                margin: 2px auto 0px;
              `}
            >
              From our results and formulations, to our refills and refillable bottles – clean always mean clean.{" "}
            </p>
          </Fade>
          <Fade ssrReveal delay={400} fraction={0.05} bottom distance="1.2rem">
            <div
              css={`
                transform: scale(0.8);
              `}
            >
              <Button link href="/products/shop/all" modifiers={["primary", "medium"]}>
                SHOP NOW
              </Button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default function HomePageHeroNew() {
  const heros = [LaundrySheets, DishSoapHero, MomDaughterBannerFillingSoapHero];
  // const heros = [LaundrySheetS amples, LaundryS heets, DishSoapHero];

  const renderHeros = () => {
    return heros.map((hero, index) => {
      return <SwiperSlide key={hero.image}>{hero}</SwiperSlide>;
    });
  };

  return (
    <>
      <AllHailAluminumHero />
    </>
  );
}
